html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 1vh;

}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c3e50;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c3e50;
}