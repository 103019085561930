.container{
    position:relative;
    width:100dvw;
    height:100dvh;
    display: flex;
    flex-direction: row; 
}



.article{
    display: flex;
    width:49dvw;
    height: 100dvh;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    word-break: keep-all;
    padding-left: 1dvw;
    /* background-color: #581845; */
   /* margin-left: 6rem;*/
    /*grid-column:span 2;
    background-color: #581845;
   */
}

.article2{
    display: flex;
    width:49dvw;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    padding-right: 1dvw;
   /* background-color: aqua; */
  }

.btn2{
  display: flex;
  width: 7rem;
  height: 2.5rem;
  background-color: #5a67fa;
  justify-content: center;
  font-family: Georgia;
  align-items: center;
  border-radius: 1rem;
  /* margin-left: 10rem; */
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 2rem;
}

.btnText2{
    font-size: 1rem;
}
.btnIcon2{
    font-size: 1.5rem;
    margin-right:0.5rem;
}
.title{
    display:block;
    width: 100%;
    font-size: 3.5rem;
    font-weight: bold;
    font-family:Georgia;
    color: rgb(212, 175, 55);
    background-image: linear-gradient(90deg, rgb(212, 175, 55), #581845);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: rgb(212, 175, 55, 0.2); 
    -moz-text-fill-color: transparent;
}

.text{
    color: #000000;
    margin-left: 5rem;
    margin-top: -3rem;
}

.imgContainer{
    position: relative;
    width: 55%;
    aspect-ratio: 1/1;
    border-radius:1rem; 
    border-radius:0.5rem; 
    border: 0.5rem solid #5a67fa;
   
}
.Img{
    position: absolute;
    top: -1.8rem;
    left: 1.8rem;
    width:100%;
    aspect-ratio: 1/1; 
    border-radius:0.5rem;
    background-color:#5a67fa;
    background: linear-gradient(to bottom, #bdc3c7, #292c86);


}


@media (width <= 700px) {
  .article{
    display: flex;
    width:95dvw;
    height: 97dvh;
    padding-left: 5dvw;
    padding-top: 3dvw;
    
  }
  .article2{
    width:99dvw;
    padding-right: 1dvw;
   
  }
  .title{
    font-size: 2rem;
  }
  .container{
    flex-direction: column;
  }
}

