.container {
    position: relative;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: row;
}


.card {
    display: flex;
    position: relative;
    width: 100dvw;
    height: 100dvh;
    grid-column: span 2;
    word-break: keep-all;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem 0 1rem;


}

.card2 {
    display: flex;
    position: relative;
    width: 100dvw;
    height: 100dvh;
    grid-column: span 2;
    word-break: keep-all;
    justify-content: center;
    align-items: flex-end;
}

.cardTitle {
    display: inline-block;
    color: #fff;
    font-size: 2rem;
    font-family: Georgia;
    margin-bottom: 1rem;
}

.cardTitle2 {
    display: inline-block;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    font-family: Georgia;
    background-image: linear-gradient(90deg, rgb(212, 175, 55), #581845);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: rgb(212, 175, 55, 0.2);
    -moz-text-fill-color: transparent;

}

.cardText {
    font-size: 1.1rem;
    color: #000000;
    margin-bottom: 0.5rem;
    font-weight: 400;
}

.card2ImgContainer {
    position: relative;
    width: 41dvw;
    aspect-ratio: 5/4;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    border: 0.5rem solid #5a67fa;
}

.Img {
    position: absolute;
    top: -2rem;
    left: 2rem;
    width: 41dvw;
    aspect-ratio: 5/4;
    border-radius: 0.5rem;
}

.btnContainer {
    display: flex;
    height: 3.5rem;
    /*background-color: #581845;*/
    align-items: flex-end;
}

.btn {
    display: flex;
    height: 2.3rem;
    width: 5rem;
    border: none;
    margin-left: 1rem;
    border-radius: 0.5rem;
    background-color: #5a67fa;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}


.Img {
    view-timeline-name: --image;
    view-timeline-axis: block;
    animation-timeline: --image;
    animation-name: show;
    animation-range: entry 25% cover 70%;
    animation-fill-mode: both;

}


@media (width <=700px) {
    .container {
        flex-direction: column;
    }

    .card {
        display: flex;
        width: 93dvw;
        height: 40dvh;
        padding-left: 2dvw;
        padding-right: 5dvw;
        padding-top: 10dvh;

    }

    .card2 {
        width: 100dvw;
        height: 40dvh;
        padding-top: 10dvh;


    }

    .cardTitle {
        font-size: 5.5dvw;
    }

    .cardTitle2 {
        font-size: 5dvw;
    }

    .cardText {
        font-size: 4dvw;
    }

    .cardbtnContainer {
        width: 90dvw;
    }

    .title {
        font-size: 2rem;
        font-weight: bold;
    }

    .container {
        flex-direction: column;
    }

    .card2ImgContainer {
        position: relative;
        width: 75dvw;
        aspect-ratio: 5/4;
        margin-bottom: 2rem;
        border-radius: 0.5rem;
        border: 0.5rem solid #5a67fa;
    }

    .Img {
        position: absolute;
        top: -2rem;
        left: 2rem;
        width: 75dvw;
        aspect-ratio: 5/4;
        border-radius: 0.5rem;
    }
}



@keyframes show {
    from {
        position: absolute;
        top: 0;
        left: 0;
    }

    to {
        position: absolute;
        top: -2rem;
        left: 2rem;
    }
}