.container{
    position:relative;
    width:100dvw;
    height:100dvh;
    display: flex;
    flex-direction: row; 
   
}


.card{
    display: flex;
    width:49dvw;
    height: 100dvh;
    flex-direction: column;
    word-break: keep-all;
    justify-content: center;
    padding-right: 5dvw;
    
}

.card2{
    display: flex;
    width:49dvw;
    height: 100dvh;
    justify-content: center;
    align-items: center;
    padding-left: 1dvw;
}

  
.title{
    width: 100%;
    height: 5rem;
    font-size:calc((1vw + 2.5rem));
    font-weight: bold;
    font-family:Georgia;
    color: rgb(212, 175, 55);
    background-image: linear-gradient(90deg, rgb(212, 175, 55), #581845);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: rgb(212, 175, 55, 0.2); 
    -moz-text-fill-color: transparent;
     word-break: keep-all;


}
.title2{
    display: inline-block;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color: #000000;
    animation: showText 2s ease-in;
    word-break: keep-all;
    
    
}
.cardbtnContainer{
   display: flex;
   height: 3rem;
   justify-content: center;
   align-items: center;
   margin-top: 1rem;
}

.cardbtn{
   height: 2.5rem;
   width: 8rem;
   border: none;
   margin-left: 1rem;
   border-radius: 0.5rem;
   background-color:#5a67fa;
   color: #fff;
   cursor: pointer;
   font-size: medium;
}

.text{
    font-size: 1.5rem;
    font-weight: bold;
    font-family:'Times New Roman';
    color: #fff;
    margin-bottom: 4rem;
    animation: showText 2s ease-in;
    word-break: keep-all;
}

.imgContainer{
    position: relative;
    width: 55%;
    aspect-ratio: 1/1;
    border-radius:1rem; 
    border-radius:0.5rem; 
    border: 0.5rem solid #5a67fa;
}

.Img{
    position: absolute;
    top: -1.8rem;
    left: 1.8rem;
    width:100%;
    aspect-ratio: 1/1; 
    border-radius:0.5rem;
    background-color:#5a67fa;
    background: linear-gradient(to bottom, #bdc3c7, #292c86);
    view-timeline-name: --image;
    view-timeline-axis: block;
    animation-timeline: --image;
    animation-name: show;
    animation-range: entry 25% cover 70%;
    animation-fill-mode: both; 
}



@media (width <= 700px) {
    .card{
      display: flex;
      width:95dvw;
      height: 100dvh;
      padding-left: 2.5dvw;
      padding-right: 2.5dvw;
      
    }
    .card2{
      width:100dvw;
      height: 90dvh;
      padding-top: 10dvh;

     
    }
    .cardbtnContainer{
      width: 90dvw;
    }
    .title{
      font-size: 2rem;
      font-weight: bold;
    }
    .container{
      flex-direction: column;
    }
    .title2{
      display: inline-block;
      font-size: 1rem;
      font-weight:400;
      font-family:Georgia;
      color: #fff;
      animation: showText 2s ease-in;
      word-break: keep-all;
      padding-left: 1rem;
      padding-right: 1rem;
        
  }

  
  }
  
  
  
@keyframes show {
    from {
        position: absolute;
        top: 0;
        left: 0;
    }
  
    to {
        position: absolute;
        top: -2rem;
        left: 2rem;
    }
  }
