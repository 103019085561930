.container {
  position: relative;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: row;

}


.title {
  font-size: calc((1vw + 3rem));
  font-weight: bold;
  font-family: Georgia;
  color: rgb(212, 175, 55);
  word-break: keep-all;
  margin-bottom: 2rem;

}

.ContactIconBody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;

}

.card {
  position: relative;
  display: flex;
  width: 100dvw;
  height: 100dvh;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  flex-direction: column;

}

.card2 {
  position: relative;
  display: flex;
  width: 50dvw;
  height: 100dvh;
  grid-column: span 2;
  justify-content: center;
  align-items: center;

}

.cardBtn {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  color: #fff;
  font-size: 1.5rem;
  background-color: rgba(51, 95, 132, 0.8);
  margin: 0.2rem;
  border-radius: 0.5rem;
}

.cardText {
  font-size: 1.5rem;
}


.github {
  width: 2rem;
  height: 2rem;
 
}

.card2Form {
  display: flex;
  width: 50%;
  height: 60%;
  border-radius: 1rem;
  flex-direction: column;
  padding: 2rem;
  background-color: rgba(51, 95, 132, 0.8);
}

.card2FormInput {
  width: 92%;
  height: 2.5rem;
  border: none;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  outline: none;
  padding: 0 4% 0 4%;
}

.card2FormInputLarge {
  width: 92%;
  height: 8rem;
  border: none;
  outline: none;
  padding: 2% 4% 2% 4%;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  text-align: start;
  font-size: 1rem;
}

.card2Btn {
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.notificationAlertBody{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;

}

.notificationAlertTexBody{
  display: flex;
  width: 20dvw;
  height: 28dvh;
  align-items: center;
  background-color: rgba(51, 95, 132, 1);
  border-radius: 1rem;
  padding-top: 5dvh;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
  box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
}

.notificationAlertBtn{
  width: 7dvw;
  height: 5dvh;
  margin-top: 10dvh;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
}

.notificationAlertBtn:active{
   opacity: 0.5;
}

.notificationAlertTexBody>p{
  color: #fff;
  font-weight: bold;
}

@media (width <=700px) {
  .container {
    flex-direction: column;
  }

  .card {
    position: relative;
    display: flex;
    width: 90dvw;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .title {
    font-size: calc((1vw + 3rem));
    font-weight: bold;
    font-family: Georgia;
    color: rgb(212, 175, 55);
    word-break: keep-all;
    margin-bottom: 2rem;
    padding-top: 2.5rem;

  }

  .cardBtn {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    color: #fff;
    font-size: 1.5rem;


  }

  .ContactIconBody {
    display: flex;
    width: 70dvw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .card2{
    width: 100dvw;
  }

  .card2Form {
    display: flex;
    width: 60dvw;
    height: 50dvw;
    border-radius: 1rem;
    flex-direction: column;
    padding: 2rem;
    background-color: rgba(51, 95, 132, 0.8);
  }

  
.notificationAlertBody{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;

}

.notificationAlertTexBody{
  display: flex;
  width: 55dvw;
  height: 40dvw;
  align-items: center;
  background-color: rgba(51, 95, 132, 1);
  border-radius: 1rem;
  padding-top: 5dvh;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
  box-shadow: -2px 0px 47px -2px rgba(0,0,0,0.75);
}

.notificationAlertBtn{
  width: 10dvw;
  height: 5dvh;
  margin-top: 10dvh;
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
}

.notificationAlertBtn:active{
   opacity: 0.5;
}

.notificationAlertTexBody>p{
  color: #fff;
  font-weight: bold;
}
}