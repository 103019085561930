.container {
    position: relative;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;

}


.text {
    display: block;
    font-size: 3rem;
    font-weight: bold;
    font-family: Georgia;
    color: rgb(212, 175, 55);
    margin-left: 3rem;
    margin-top: 5rem;
    background-image: linear-gradient(90deg, rgb(212, 175, 55), #581845);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: rgb(212, 175, 55, 0.2);
    -moz-text-fill-color: transparent;

}

.bodyCard {
    position: relative;
    width: 90dvw;
    height: 100dvh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
    grid-auto-rows: 10rem;
    grid-auto-flow: dense;
    gap: 1rem;
    padding-left: 5dvw;
    padding-top: 5vh;
    padding-right: 5dvw;
    padding-bottom: 2vh;

}

.bodyCard2 {
    display: none;
}

.card {
    display: flex;
    width: 10rem;
    height: 10rem;
    background-color: rgba(51, 95, 132, 0.4);
    grid-column: auto;
    border-radius: 0.3rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5vh;
}

.card:hover {
    -webkit-box-shadow: 0px 6px 11px 6px rgba(212, 175, 55, 0.8);
    -moz-box-shadow: 0px 6px 11px 6px rgba(212, 175, 55, 0.8);
    box-shadow: 0px 6px 11px 6px rgba(212, 175, 55, 0.8);
}

.Image {
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;

}

.imageText {
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
}

.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}


@media screen and (max-width:767px) {

    .body {
        position: absolute;
        top: 10vh;
        height: 90vh;
        width: 100%;
        padding-bottom: 0vh;
        overflow: auto;
    }

    .text {
        margin-top: 10dvh;
        margin-left: 2dvw;
        font-size: 2.3rem;
    }

    .bodyCard {
        display: none;

    }

    .bodyCard2 {
        position: relative;
        width: 90dvw;
        height: 100dvh;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 10rem), 1fr));
        grid-auto-rows: 10rem;
        grid-auto-flow: dense;
        gap: 1rem;
        padding-left: 5dvw;
        padding-top: 5vh;
        padding-right: 5dvw;
        padding-bottom: 2vh;
    
    }

    .btnControlContainer{
        position: absolute;
        top: 65dvh;
        display: flex;
        width: 100dvw;
        height: 10dvh;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

   .btnControl{
     width: 20dvw;
     height: 5dvh;
     font-size: 4.2dvw;
     margin-left: 4dvw;
     border-radius: 0.5rem;
     border: none;
   }
   .btnControl:active{
    opacity: 0;
  }

   .controlText{
     font-size: 5dvw;
     font-weight: bold;
     color: #fff;
     margin-left: 2dvw;
   }
}


@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
    }

    to {
        -webkit-transform: scale(1);
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}